import UserHeading from "../components/UserHeading";
import UserGift from "../components/UserGift";
import UserList from "../components/UserList";



function UserPage() {
  return (
    <div id="mainContent">
      <UserHeading />
      {
        localStorage.getItem("userPage") == null ||
        localStorage.getItem("userPage") === localStorage.getItem("fullName") 
        ? <div><UserGift/><br/></div>
        : <></>
      }

      <UserList />
    </div>
  );
}

export default UserPage;