/* Hamburger menu for the user list on mobile. */

function HamburgerMenu({isOpen}) {
    return (
        <>
            <span className="hamburger">
                <span className="burger burger1" />
                <span className="burger burger2" />
                <span className="burger burger3" />
            </span>

            <style jsx="true">{`
                .burger1 {
                    transform: ${isOpen ? 'rotate(45deg)': 'rotate(0)'};
                }
                .burger2 {
                    transform: ${isOpen ? 'translateX(100%)': 'translateX(0)'};
                    opacity: ${isOpen ? 0 : 1};
                }
                .burger3 {
                    transform: ${isOpen ? 'rotate(-45deg)': 'rotate(0)'};
                }
            `}</style>
        </>
    );
}

export default HamburgerMenu;