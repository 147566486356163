/* Create a letter based on a kid and a gift. */

import React, { useState, useEffect } from "react";
import Axios from "axios";
import {host} from "../constants/global";

const kids = [
  "paul",
  "dani",
  "luc",
  "sam",
  "inde",
  "kailand",
  "alenna",
  "nova",
  "avon",
  "jack",
  "ella",
];
const chosenKid = kids[Math.floor(Math.random() * kids.length)];

function GiftLetter() {
  const [gift, setGift] = useState("something nice");

  useEffect(() => {
    var url = {host}.host+"rndGift";
    Axios.post(url, {
      kid: chosenKid,
    }).then((response) => {
      setGift(response.data);
    });
  }, []);

  return (
    <div id="homeGift">
      <p>Dear Santa,</p>
      <br />
      <p>
        I have been incredibly good this year and hope you can bring me {gift}{" "}
        for Christmas if you aren't too busy. Thanks, and please say "hi" to
        Mrs. Claus and the elves for me.
      </p>
      <p>Your Friend on the Nice List,</p>
      <p>{chosenKid}</p>
      <img src={`images/kids/${chosenKid}.jpg`} alt={chosenKid} />
    </div>
  );
}

export default GiftLetter;
