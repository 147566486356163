import React, { useContext, useState } from "react";
import Axios from "axios";
import {host} from "../constants/global";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import Heading from "../components/Heading";
import Button from "../components/Button";
import GiftLetter from "../components/GiftLetter";
import { AuthContext } from "../authContext";

function Home() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  const { setUser } = useContext(AuthContext);

  const login = (event) => {
    event.preventDefault();
    setShowLoader(true);
    var url = {host}.host+"login";
    Axios.post(url, {
      username: username,
      password: password,
    })
      .then((response) => {
        setShowLoader(false);
        localStorage.setItem("username", username);
        localStorage.setItem("fullName", response.data.user.fullName);
        localStorage.setItem("userPage", response.data.user.fullName);

        if (response.data.token) {
          // Store the token in a cookie
          Cookies.set("jwt", response.data.token, { expires: 1 });
          setUser(true);
          navigate("/");
        } else {
          setLoginStatus(response.data.message);
        }
      })
      .catch((error) => {
        alert(error);
        setShowLoader(false);
        setLoginStatus("There was a problem logging in.");
      });
  };

  return (
    <div className="App">
      <div id="mainContent">
        <Heading />
        <div className="login">
          <form onSubmit={login}>
            <label>Username </label>
            <input
              type="text"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <br />

            <label>Password </label>
            <input
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <br />
            <div className="userLinks">
              <Link to="/forgot">Forgot Password</Link> •{" "}
              <Link to="/register">Register</Link>
            </div>
            <br />
            <br />
            <div className="buttonContainer">
              <Button
                text="Sign In"
                loading={showLoader}
                disabled={showLoader}
              />
            </div>
          </form>

          <div className="status">
            <h2>{loginStatus}</h2>
          </div>
        </div>
      </div>
      <GiftLetter />
      <div id="userMenu" style={{display:"none"}}></div>
    </div>
  );
}
export default Home;