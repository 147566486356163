import React, { useState } from "react";
import Axios from "axios";
import {host} from "../constants/global";
import Heading from "../components/Heading";
import Button from "../components/Button";
import { Link } from "react-router-dom";

function Register() {
  const [usernameReg, setUsernameReg] = useState("");
  const [passwordReg, setPasswordReg] = useState("");
  const [nameReg, setNameReg] = useState("");
  const [registrationStatus, setRegistrationStatus] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showLink, setShowLink] = useState(false);

  const register = (event) => {
    event.preventDefault();
    setShowLoader(true);
    var url = {host}.host+"register";
    Axios.post(url, {
      username: usernameReg,
      password: passwordReg,
      fullName: nameReg,
    })
      .then((response) => {
        setShowLoader(false);
        setRegistrationStatus(response.data.message);
        setShowLink(true);
      })
      .catch((error) => {
        setShowLoader(false);
        setRegistrationStatus("There was a problem registering.");
      });
  };

  return (
    <div className="App">
      <div id="mainContent">
        <Heading />
        <div className="registration">
          <form onSubmit={register}>
            <label>&nbsp;Username </label>
            <input
              type="text"
              onChange={(e) => {
                setUsernameReg(e.target.value);
              }}
            />
            <br></br>

            <label>&nbsp;Password </label>
            <input
              type="text"
              onChange={(e) => {
                setPasswordReg(e.target.value);
              }}
            />
            <br />
            <label>Full Name </label>
            <input
              type="text"
              onChange={(e) => {
                setNameReg(e.target.value);
              }}
            />
            <br />
            <br />
            <div className="buttonContainer">
              <Button
                text={"Register"}
                loading={showLoader}
                disabled={showLoader}
              />
            </div>
          </form>

          <div className="status">
            <h2>{registrationStatus}</h2>
            <h2 style={{ display: showLink ? "block" : "none" }}>
              <Link to="/">Return to Home</Link>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
