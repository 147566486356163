// Submit button that can display a loading animation

import { ReactComponent as Loader } from '../assets/loader.svg'

function Button({text, loading, disabled}) {
    return (
        <button className="submit-btn" type="submit" disabled={disabled}>
            {!loading ? text:<Loader className="spinner" />}
        </button>
    );
}

export default Button;