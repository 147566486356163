/* Gifts for the logged-in user. */

import React, { useState } from "react";
import Axios from "axios";
import {host} from "../constants/global";
import Button from "../components/Button";

function UserGift() {
  const [gift, setGift] = useState("");
  const [cost, setCost] = useState("");
  const [link, setLink] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  Axios.defaults.withCredentials = true;

  const addGift = (event) => {
    event.preventDefault();
    setShowLoader(true);
    var url = {host}.host+"addGift";
    Axios.post(url, {
      gift: gift,
      cost: cost,
      link: link,
    })
      .then((response) => {
        setShowLoader(false);
        if (response.data.message) {
          alert(response.data.message);
        } else {
          alert(gift + " has been added to your Christmas list.");
          window.location.reload();
        }
      })
      .catch((error) => {
        alert(error);
        setShowLoader(false);
      });
  };

  return (
    <div id="addGift">
      <form id="giftForm" onSubmit={addGift}>
        <label>Name of Gift </label>
        <input
          type="text"
          onChange={(e) => {
            setGift(e.target.value);
          }}
        />
        <br />

        <label>Cost of Gift </label>
        <input
          type="number"
          step="0.01"
          onChange={(e) => {
            setCost(e.target.value);
          }}
        />
        <br />

        <label>Link to Gift </label>
        <input
          type="url"
          placeholder="Optional"
          onChange={(e) => {
            setLink(e.target.value);
          }}
        />
        <br />
        <br />
        <br />
        <div className="buttonContainer">
          <Button text="Add Gift" loading={showLoader} disabled={showLoader} />
        </div>
      </form>
    </div>
  );
}

export default UserGift;
