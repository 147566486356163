// Gift components that are display in user Christmas lists

import Axios from "axios";
import Modal from 'react-bootstrap/Modal';
import React, {useState } from "react";
import {host} from "../constants/global";

function Gift({type, value, index}) {
  const [gift, setGift] = useState(-1); // This variable is set with the ID of a gift when a button is clicked
  const [showDelete, setShowDelete] = useState(false);
  const [showPurchase, setShowPurchase] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const handleShowPurchase = () => setShowPurchase(true);
  const handleClosePurchase = () => setShowPurchase(false);
    
  const handleDelete = (id) => {
    setGift(id);
    handleShowDelete();
  };

  const handlePurchase = (id) => {
    setGift(id);
    handleShowPurchase();
  };
 
  const confirmDelete = () => {
    var url = {host}.host+"deleteGift";
    Axios.post(url, { gift })
        .then((response) => {
            alert(response.data.message || response.data);
            document.getElementById("uGift"+gift).remove();
            handleCloseDelete();
        })
        .catch((error) => {
            alert(error);
        });
  };

  const confirmPurchase = () => {
    var url = {host}.host+"purchaseGift";
      Axios.post(url, {
        gift,
        name: localStorage.getItem("fullName"),
      })
        .then((response) => {
          alert(response.data.message || response.data);
          var purchasedGift = document.getElementById("uGift"+gift).childNodes;
          purchasedGift[1].style.textDecoration = "line-through";
          handleClosePurchase();
        })
        .catch((error) => {
          alert(error);
        });
    };
    return (
        <>
          <Modal show={showDelete} centered onHide={handleCloseDelete}>
          <Modal.Header>
            <Modal.Title>Delete Gift</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please confirm that you would like to delete this gift.</Modal.Body>
          <Modal.Footer>
            <button onClick={handleCloseDelete}>
              Cancel
            </button>
            <button onClick={confirmDelete}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={showPurchase} centered onHide={handleClosePurchase}>
          <Modal.Header>
            <Modal.Title>Purchase Gift</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please confirm that you would like to purchase this gift.</Modal.Body>
          <Modal.Footer>
            <button onClick={handleClosePurchase}>
              Cancel
            </button>
            <button onClick={confirmPurchase}>
              Purchase
            </button>
          </Modal.Footer>
        </Modal>
       
        {/* Generate a delete button and gift with or without a link */}
        <div id={"uGift" + value.key}>
          {type === "delete" ?
            <><button className="delete-btn" value={value.key} key={"btn-"+value.key} onClick={()=> handleDelete(value.key)} >
              {type}
            </button>
            {/* WITH A LINK */
            value.link !== "" ? <><a href={value.link}>{value.gift}</a> - <span>${value.price.toFixed(2)}</span></>
            :
            /* WITHOUT A LINK */
            <span>{value.gift} - ${value.price.toFixed(2)}</span>
            }
            </>
          :
          /* Genereate a purchase button and gift with or without a link.
             If the gift has been purchased, the button is disabled, the gift is crossed out, and the purchaser is listed. */
            <>
              <button className="purchase-btn" value={value.key} key={"btn-"+value.key} disabled={value.bought === 1 ? true : false} onClick={()=> handlePurchase(value.key)}  >
                {type}
              </button>
              <>
              {/* PURCHASED GIFT */
              value.bought === 1 ? <span style={{"textDecoration": "line-through"}}>
                {/* PURCHASED GIFT WITH A LINK */
                value.link !== "" ? <><a href={value.link}>{value.gift}</a> - <span>${value.price.toFixed(2)} by {value.giver}</span></>
                :
                /* PURCHASED GIFT WITHOUT A LINK */
                <span>{value.gift} - ${value.price.toFixed(2)} by {value.giver}</span>
                }
                  </span>
              : /* AVAILABLE GIFT */
                <>
                { /* AVAILABLE GIFT WITH A LINK */
                value.link !== "" ? <><a href={value.link}>{value.gift}</a> - <span>${value.price.toFixed(2)}</span></>
                :
                /* AVAILABLE GIFT WITHOUT A LINK */
                <span>{value.gift} - ${value.price.toFixed(2)}</span>
                }
                </>
              }
              </>
            </>
          }
        </div>
        </>
    );
}

export default Gift;