import React, { useState } from "react";
import Axios from "axios";
import {host} from "../constants/global";
import Heading from "../components/Heading";
import Button from "../components/Button";
import { Link } from "react-router-dom";

function ForgotPassword() {
  const [usernameReg, setUsernameReg] = useState("");
  const [passwordReg, setPasswordReg] = useState("");
  const [passwordConfirmReg, setPasswordConfirmReg] = useState("");
  const [registrationStatus, setRegistrationStatus] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showLink, setShowLink] = useState(false);

  const resetPassword = (event) => {
    event.preventDefault();
    var url = {host}.host+"forgot";
    if (passwordReg !== passwordConfirmReg) {
      setRegistrationStatus("Your password and confirmation do not match.");
    } else {
      setShowLoader(true);
      Axios.post(url, {
        username: usernameReg,
        password: passwordReg,
      })
        .then((response) => {
          setShowLoader(false);
          setShowLink(true);
          setRegistrationStatus(response.data.message);
        })
        .catch((error) => {
          setShowLoader(false);
          setRegistrationStatus("There was a problem resetting your password.");
        });
    }
  };

  return (
    <div className="App">
      <div id="mainContent">
        <Heading />
        <div className="forgot">
          <form onSubmit={resetPassword}>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;Username </label>
            <input
              type="text"
              placeholder="Current Username..."
              onChange={(e) => {
                setUsernameReg(e.target.value);
              }}
            />
            <br></br>

            <label>New Password </label>
            <input
              type="password"
              placeholder="New Password..."
              onChange={(e) => {
                setPasswordReg(e.target.value);
              }}
            />
            <br />
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Confirm </label>
            <input
              type="password"
              placeholder="Confirm New Password..."
              onChange={(e) => {
                setPasswordConfirmReg(e.target.value);
              }}
            />
            <br />
            <br />
            <div className="buttonContainer">
              <Button
                text="Reset Password"
                loading={showLoader}
                disabled={showLoader}
              />
            </div>
          </form>

          <div className="status">
            <h2>{registrationStatus}</h2>
            <h2
              style={{
                textDecoration: "underline",
                display: showLink ? "block" : "none",
              }}
            >
              <Link to="/" className="returnHome">
                Return to Home
              </Link>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;