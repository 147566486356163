import {useEffect} from "react";

// Randomly pick a background image
// Add images to the following list and the images folders to use more
const bgImages = ['images/2021cousins.jpg','images/2022cousins.jpg'];

function BackgroundImage() {
    const backgroundImage = bgImages[Math.floor(Math.random() * bgImages.length)];
    useEffect(() => {
        document.body.style.backgroundImage = `url(${backgroundImage})`;
        document.body.style.backgroundPosition = 'center';
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.width = '100vw';
        document.body.style.height = '150vw';
        document.body.style.resize = 'both';
      });
}

export default BackgroundImage;