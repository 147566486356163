import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthContext, AuthProvider } from "./authContext";
import Home from "./pages/Home";
import Register from "./pages/Register";
import UserPage from "./pages/UserPage.js";
import BackgroundImage from "./components/BackgroundImage";
import ForgotPassword from "./pages/ForgotPassword";
import { Space, Spin } from "antd";
export default function App() {
  const { user, loading } = useContext(AuthContext);
  BackgroundImage();

  return (
    <div>
      {loading ? (
        <div className="loader">
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={user ? <UserPage /> : <Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot" element={<ForgotPassword />} />
        </Routes>
      )}
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
