/* Site Heading */

import HomeButton from "./HomeButton";

function Heading() {
    return (
    <div id="heading">
        <HomeButton/><h1>A Miller Family Christmas</h1>
    </div>
        );
}

export default Heading;