/* User Navigation Menu */

import React, { useState, useEffect } from "react";
import Axios from "axios";
import {host} from "../constants/global";
import { createRoot } from "react-dom/client";
import HamburgerMenu from "./HamburgerMenu";

function Nav() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const userPage = (event) => {
    event.preventDefault(event);
    localStorage.setItem("userPage", event.target.innerText);
    window.location.reload();
  };

  useEffect(() => {
    if (document.querySelector("#userMenu"))
    {
      var url = {host}.host+"users";
      Axios.post(url).then((response) => {
        var tempUsers = [];
        for (var user in response.data) {
          tempUsers.push(response.data[user].fullName);
        }
        
        const userMenu = createRoot(document.getElementById("userMenu"));
        userMenu.render(
          <>
            <ul>
              {tempUsers.map((user) => (
                <li key={user}>
                  <a href="" onClick={userPage}>
                    {user}
                  </a>
                </li>
              ))}
            </ul>
          </>
        );
      });
    }
  }, []);

  return (
    <div>
      <nav id="userMenu">
        <ul></ul>
      </nav>

      <div className="hamburger" onClick={toggleHamburger}>
        <HamburgerMenu isOpen={hamburgerOpen} />
      </div>

      <style jsx="true">{`
        #userMenu ul {
          display: ${hamburgerOpen ? "inline" : "none"};
          background-color: green;
          margin-top: 30px;
          position: absolute;
          padding: 5px;
          font-size: x-large;
          border-radius: 15px;
          border: solid white 2px;
        }

        @media (min-width: 700px) {
          #userMenu ul {
            display: inline;
            margin-top: 0px;
            font-size: medium;
          }
        }
      `}</style>
    </div>
  );
}

export default Nav;
