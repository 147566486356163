// List of user gifts

import React, { useEffect, useState } from "react";
import Axios from "axios";
import {host} from "../constants/global";
import Gift from "./Gift";

function UserList({ username }) {
  const [gifts, setGifts] = useState([]);
  const getGifts = async () => {
    var url = {host}.host+"userList";
    if (
      localStorage.getItem("userPage") == null ||
      localStorage.getItem("userPage") === localStorage.getItem("fullName")
    ) {
      Axios.post(url, {
        username: localStorage.getItem("username")
          ? localStorage.getItem("username")
          : "",
      }).then((response) => {
        const allGifts = [];
        Object.values(response.data).forEach((value, index) => {
          const newGift = {
            type: "delete",
            value: value,
            key: index
          };
          allGifts.push(newGift);
        });

        setGifts(allGifts);
      });
    } else {
      url = {host}.host+"getUsername";
      Axios.post(url, {
        name: localStorage.getItem("userPage")
          ? localStorage.getItem("userPage")
          : "",
      }).then((response) => {
        url = {host}.host+"userList";
        Axios.post(url, {
          username: response.data[0].username,
        }).then((response) => { 
          const allGifts = [];
          Object.values(response.data).forEach((value, index) => {
            const newGift = {
              type: "purchase",
              value: value,
              key: index
            };
            allGifts.push(newGift);
          });
          setGifts(allGifts);
        });
      });
    }
  };

  useEffect(() => {
    getGifts();
  }, []);

  return (
      <div id="giftList">{gifts.map(gift => (
        <Gift type={gift.type} value={gift.value} key={gift.key} />
      ))}
      </div>
  )
}

export default UserList;
